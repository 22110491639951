import React from "react";
import { BoxTitle } from "../../components/boxTitle/boxTitle";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import Title from "../../components/title/Title";
import "./policy.scss";
const Policy = () => {
  const policy__information = {
    margin: "2.66em 0 5.33em",
  };

  const styleBold = {
    fontWeight: 'Bold'
  }

  return (
    <div>
      <Header />
      <main className="policy">
        <Title addClass={"policy__title"}>
          Privacy Policy / Security Policy
        </Title>
        <div className="content">
          <p className="content_text_bold_first">
            プライバシーポリシー / セキュリティポリシー
          </p>
          <BoxTitle text="Privacy Policy" />
          <p>
            株式会社BluseTech
            （以下「当社」といいます。）は、個人情報（個人情報の保護に関する法律（以下「個人情報保護法」といいます。）第2条第1項に定義される個人情報をいいます。以下同じ。）を適切に管理するために、適用ある法令及びガイドラインを遵守するほか、以下のとおりプライバシーポリシー（以下「本ポリシー」といいます。）を制定し、遵守します。なお、本ポリシーで使用する用語の意味は、個人情報保護法に準拠するものとします。
          </p>
          <p className="content_text_bold">1. 利用目的の特定</p>
          <p>
            当社は、法令等に定めのある場合を除き、次に掲げる利用目的の達成に必要な範囲を超えて、個人情報を取り扱いません。ただし、当社が個人情報を取得する際に、別途利用目的の同意を得た場合には、次に掲げる利用目的に加え、同意を得た利用目的の範囲内で当該個人情報を取り扱います。
          </p>
          <p>(1) 当社のサービスの提供</p>
          <p>(2) 当社のサービスの企画・研究開発</p>
          <p>(3) 当社に対するお問い合わせ、ご質問などへのご回答</p>
          <p>
            (4)
            当社が開催又は出展するフェア、イベント、セミナーなどへのご案内その他の当社のサービスの広告・宣伝・販売促進活動（メールマガジンの送付を含みます。）
          </p>
          <p>
            (5)
            当社の事業（システム開発事業、ソリューションサービス開発事業、運用保守事業
            その他ITソリューションに関連する事業をいいます。以下同じ。）の遂行のための社内資料の作成及び使用
          </p>
          <p>
            (6)
            当社の事業に関するマーケティング活動のための調査資料の作成及び使用
          </p>
          <p>
            (7)
            当社の経営状況、財務状況及び業績動向に関する情報及び当社のサービスに関する情報の発信その他のお客様との関係構築
          </p>
          <p>
            (8)
            業務実施時の諸連絡、業務記録の管理など、当社の業務管理上必要な範囲における利用
          </p>
          <p>
            (9)
            会社法、商法その他の法令に基づく権利の行使及び義務の履行等の対応（各種手続、書類送付、株主データ管理等）及びその管理
          </p>
          <p>(10) 当社の社内統計資料の作成（年齢構成、性別構成等）</p>
          <p>
            (11)
            当社の採用活動及び採用後の人事業務（給与支払、勤怠管理、緊急連絡等）
          </p>
          <p className="content_text_bold">2. 個人情報の取得について</p>
          <p>当社は、適法かつ公正な手段によって、個人情報を取得します。</p>
          <p className="content_text_bold">3. 個人情報の第三者提供について</p>
          <p>
            当社は、個人情報を、事前にご本人の同意を得ることなく、第三者（第4項の共同利用者及び第5項の委託先を除きます。）に提供しません。ただし、人の生命、身体又は財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるときなど、法令が認める場合には、ご本人の同意を得ることなく個人情報の第三者提供を行うことがあります。
          </p>
          <p className="content_text_bold">4. 個人情報の共同利用について</p>
          <p>
            当社は、第1項における利用目的達成のために、お客様の個人情報を共同利用する場合は、共同利用を行う前に、共同利用に関する事項をお客様に通知し、又は容易に知りうる状態に置きます。
          </p>
          <p className="content_text_bold">
            5. 個人情報の委託先への提供について
          </p>
          <p>
            当社は、第1項記載の利用目的の達成に必要な範囲内において、個人情報の取扱いに関する業務の全部又は一部を第三者に委託することがあります。この場合、当社は、委託先が十分な個人情報の保護水準を満たしていることを確認します。また、当社は、委託先に対し、個人情報の保護のための必要かつ適切な監督を行います。
          </p>
          <p className="content_text_bold">6. 安全管理措置について</p>
          <p>
            当社は、取り扱う個人情報の漏えい、滅失又は毀損の防止及び安全管理のため、情報セキュリティ関連規程に従って必要かつ適切な措置を実施します。
          </p>
          <p className="content_text_bold">
            7. 開示・訂正等・利用停止等について
          </p>
          <p>
            当社は、ご本人から個人情報の利用目的の通知・開示・訂正等・利用停止等・第三者への提供の停止などのご請求がある場合には、ご本人からの請求であることを確認した上で、法令の定めに従い、個人情報の利用目的の通知・開示・訂正等・利用停止等・第三者への提供の停止を行います。
          </p>
          <p className="content_text_bold">8. 個人情報管理体制</p>
          <p>
            当社は、個人情報保護責任者の管理のもと個人情報を取り扱います。当社が保有する個人情報の利用目的の通知・開示・訂正等・利用停止等・第三者への提供の停止などのご請求、又は当社の個人情報の取扱いに関するご意見、ご質問、苦情又は相談につきましては、下記までご連絡ください。
          </p>
          <div style={policy__information}>
            <h4>株式会社BluseTech 個人情報管理担当</h4>
            <div className="content_text_table">
              <p style={styleBold}>住所</p>
              <div className="content_text_right">
                <p>〒107-6235</p>
                <p>東京都港区赤坂九丁目7番1号ミッドタウン・タワー32階</p>
              </div>
            </div>
            <div className="content_text_table">
              <p style={styleBold}>E-mail</p>
              <div className="content_text_right">
                <a href="mailto:contact@blusetech.co.jp ">
                  contact@blusetech.co.jp
                </a>
              </div>
            </div>
          </div>
          <p className="content_text_bold">9. 本ポリシーの改定</p>
          <p>
            当社は、法令の改正及び当社の業務内容の変更などに応じて、本ポリシーの内容を改定することがあります。当社が本ポリシーの内容を改定した場合には、当ウェブサイトに掲載してお知らせします。
          </p>
          <p className="text_footer">以上</p>
          <BoxTitle text="Security Policy" />
          <p>
            株式会社BluseTech
            （以下「当社」といいます。）は、このセキュリティポリシーに掲げる以下の項目を当社の役員及び従業員に周知徹底することにより、当社が取り扱う全ての情報資産を適切に保護します。
          </p>
          <p className="content_text_bold">
            1. 法令等の遵守（コンプライアンスの確立）
          </p>
          <p>
            当社は、情報セキュリティへの取組みを、関連する法令、規格、規範及びお客様との契約に準拠・適合したものとするとともに、これらの法令等を遵守します。
          </p>
          <p className="content_text_bold">2. 組織的安全管理措置の実施</p>
          <p>
            当社は、安全管理措置を講じ、情報資産の取扱状況を確認し、情報資産の漏えい等に対応するための組織体制を整備するとともに、これらの適切な運用を行います。
          </p>
          <p className="content_text_bold">3. 物理的安全管理措置の実施</p>
          <p>
            当社は、情報資産を取り扱うべき区域を特定して施錠管理し、情報資産の盗難等及び漏えい等を防止し、取扱いの終了後の削除等必要な措置を講じます。
          </p>
          <p className="content_text_bold">4. 技術的安全管理措置の実施</p>
          <p>
            当社は、情報資産に対するアクセス権を必要かつ適切な範囲に限定して設定し、アクセス制御を行い、アクセス権を有する者の識別と認証を行い、外部からの不正アクセス等の防止を図り、盗難等及び漏えい等の防止を徹底します。
          </p>
          <p className="content_text_bold">5. 人的安全管理措置の実施</p>
          <p>
            当社は、教育、研修を通して役員及び従業員の情報セキュリティに関する知識及び意識の向上を図るとともに、全社を挙げて情報セキュリティを実践します。
          </p>
          <p className="content_text_bold">6. 委託先管理</p>
          <p>
            当社は、教育、研修を通して役員及び従業員の情報セキュリティに関する知識及び意識の向上を図るとともに、全社を挙げて情報セキュリティを実践します。
          </p>
          <p className="content_text_bold">
            7. リスクに応じた安全管理措置の実施
          </p>
          <p>
            当社は、お客様及び当社の情報資産についてリスクアセスメントを実施し、それぞれのリスクに応じた最適な安全管理措置を講じます。
          </p>
          <p className="content_text_bold">8. 事故への対応</p>
          <p>
            当社は、お客様及び当社の情報資産についてリスクアセスメントを実施し、それぞれのリスクに応じた最適な安全管理措置を講じます。
          </p>
          <p className="content_text_bold">9. 事業の継続</p>
          <p>
            当社は、やむを得ない事由により事業活動が中断することがあったとしても、中断期間が長期化することがないよう、事業継続計画を構築し、情報資産を障害又は災害から保護し、事業活動を速やかに再開するために必要な措置を講じます。
          </p>
          <p className="text_footer">以上</p>
          <BoxTitle text="反社会的勢力に対する基本方針" />
          <p>
            株式会社BluseTech
            は、次のとおり反社会的勢力に対する基本方針を定め、役職員一同これを遵守することにより、業務の適切性と安全性の確保に努めます。
          </p>
          <p className="content_text_bold">（1） 組織としての対応</p>
          <p>
            反社会的勢力に対しては、経営トップによるリードの下で、会社組織としての対応を行います。また、反社会的勢力への対応を行う従業員の安全を確保します。
          </p>
          <p className="content_text_bold">（2） 外部の専門機関との連携</p>
          <p>
            常時から、警察、暴力追放運動推進センター、弁護士等の外部の専門機関と有効な連携関係を構築することに努めます。
          </p>
          <p className="content_text_bold">
            （3） 民事と刑事の両面からの法的対応
          </p>
          <p>
            反社会的勢力による不当要求を拒絶し、必要に応じて民事および刑事の両面から毅然とした法的対応を行います。
          </p>
          <p className="content_text_bold">（4） 取引を含めた一切の関係遮断</p>
          <p>
            反社会的勢力に対しては、取引関係を含めた一切の関係遮断を行います。
          </p>
          <p className="content_text_bold">（5） 裏取引や資金提供の禁止</p>
          <p>
            反社会的勢力との裏取引、反社会的勢力への資金提供は絶対に行いません。
          </p>
          <p className="text_footer">以上</p>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Policy;
