import icnR from '../../assets/img/icn_arw_pc.svg';
import { BtnTab } from '../../components/buttons/Buttons';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import './news.scss';
import { useEffect, useState, useMemo } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';

const News = () => {
  const [category, setCategory] = useState('すべて');
  const [pagination, setPagination] = useState([]);
  const [number, setNumber] = useState(1);
  const [newsData, setNewsData] = useState([]);
  const [categories, setCategories] = useState([]);

  const compareDate = (dateStr1, dateStr2) => {
    const date1 = new Date(dateStr1);
    const date2 = new Date(dateStr2);
    return date2 - date1;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/assets/json/news.json');
        const data = await response.json();

        // Get unique categories from the data and set the array of categories
        let uniqueCategories = [];
        data.forEach((item) => {
          if (!uniqueCategories.includes(item.categorie01)) {
            uniqueCategories.push(item.categorie01);
          }
        });
        setCategories(uniqueCategories);

        // Set the array of news items
        setNewsData(data);
      } catch (error) {
        console.error('Error fetching news data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    // Filter news items based on the selected category or show すべて items
    let items = [];
    if (category === 'すべて') items = [...newsData];
    else items = newsData.filter((item) => item.categorie01 === category);

    // Sort the filtered news items based on date in descending order (newest first)
    items.sort((a, b) => compareDate(a.date, b.date));

    // Calculate the pagination for the filtered data
    const pageSize = 10;
    const temp = Array.from(
      { length: Math.ceil(items.length / pageSize) },
      (_, index) => items.slice(index * pageSize, index * pageSize + pageSize)
    );
    setPagination(temp);

    // Reset the current page number to the first page
    setNumber(0);
  }, [category, newsData]);

  const cloneCurrentTableData = useMemo(() => {
    let cloneNewsData = [];
    switch (category) {
      case 'プレスリリース':
        return (cloneNewsData = newsData.filter(
          (item) => item.categorie01 === category
        ));
      case 'お知らせ':
        return (cloneNewsData = newsData.filter(
          (item) => item.categorie01 === category
        ));
      case 'サービスニュース':
        // eslint-disable-next-line
        return (cloneNewsData = newsData.filter(
          (item) => item.categorie01 === category
        ));
      default:
        return newsData;
    }
  }, [newsData, category]);

  const currentTableData = useMemo(() => {
    const pageSize = 10;
    const firstPageIndex = (number - 0) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return cloneCurrentTableData.slice(firstPageIndex, lastPageIndex);
  }, [cloneCurrentTableData, number]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [currentTableData]);

  const styleItemStatus = (categorie01) => {
    switch (categorie01) {
      case 'サービスニュース':
        return 'rows__item__status__press_release';
      case 'お知らせ':
        return 'rows__item__status__notice';
      case 'プレスリリース':
        return 'rows__item__status__service_news';
      default:
        return 'rows__item__status__press_release';
    }
  };

  return (
    <>
      <Header />
      <div className="news">
        <div className="news__content">
          <div className="section-title">
            <div className="section-title__dot">
              <div className="section-title__flash" />
            </div>
            <span className="section-title__txt">NEWS</span>
          </div>
          <div className="news__tabs">
            <div className="new__tabs__btn">
              {['すべて', ...categories].map((tab, index) => (
                <BtnTab
                  key={index}
                  onClick={() => setCategory(tab)}
                  addClass={category === tab ? 'active' : null}
                >
                  {tab}
                </BtnTab>
              ))}
            </div>

            <div className="rows new-data">
              {currentTableData?.map((item, index) => {
                return (
                  <div className="rows__item" key={index}>
                    <div className="rows__item__left">
                      <div className="rows__item__date">
                        <p>{item?.date}</p>
                      </div>
                      <div
                        className={`rows__item__status ${styleItemStatus(
                          item?.categorie01
                        )}`}
                      >
                        <p>{item?.categorie01}</p>
                      </div>
                      <div
                        className="rows__item__content"
                        data-tooltip-id={`my-tooltip-${index}`}
                      >
                        <p>{item?.name}</p>
                      </div>
                    </div>
                    <ReactTooltip
                      id={`my-tooltip-${index}`}
                      place="top"
                      content={item?.name}
                    />
                    <div className="rows__item__right">
                      {item?.url && (
                        <button
                          className="rows__item__btn"
                          onClick={() => {
                            window.location.href = `/assets${item?.url}`;
                          }}
                        >
                          <img src={icnR} alt="Right icon" />
                        </button>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          {/* pagination */}
          <div className="pagination">
            <button
              className={`prev ${number === 0 ? 'disableBtn' : ''}`}
              onClick={() =>
                setNumber((number) => (number > 0 ? number - 1 : number))
              }
              disabled={number === 0 ? true : false}
            >
              &lt;
            </button>
            {pagination?.map((_, index) => (
              <button
                key={index}
                className={`number ${number === index ? 'active' : null}`}
                onClick={() => setNumber(index)}
                disabled={number === index}
              >
                {index + 1}
              </button>
            ))}
            <button
              className={`next ${
                number === pagination?.length - 1 ? 'disableBtn' : ''
              }`}
              onClick={() =>
                setNumber((number) =>
                  number < pagination?.length - 1 ? number + 1 : number
                )
              }
              disabled={number === pagination?.length - 1}
            >
              &gt;
            </button>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default News;
