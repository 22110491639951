// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper {
  margin: 0 auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.container {
  padding: 20px 0;
  width: 85%;
  margin: 0 auto;
}

@media (max-width: 360px) {
  .wrapper {
    max-width: 360px;
    margin: auto;
  }
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Montserrat", sans-serif;
  background-color: #f5f5f4;
  margin: 0 auto;
}

input {
  position: relative;
}

a {
  text-decoration: none;
}

.box_container {
  border: 1px solid;
  line-height: 40px;
  margin-top: 100px;
  margin-bottom: 60px;
  display: inline-block;
  padding: 0 40px;
}
.box_container .box_content {
  text-align: center;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .box_container .box_content {
    font-size: 16px;
  }
}
@media screen and (max-width: 375px) {
  .box_container {
    padding: 0 10px;
    margin-top: 50px;
  }
  .box_container .box_content {
    font-size: 14px;
  }
}`, "",{"version":3,"sources":["webpack://./src/scssStyles/_global.scss","webpack://./src/components/boxTitle/boxTitle.scss","webpack://./src/scssStyles/_mixins.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,iBAAA;EACA,aAAA;EACA,sBAAA;EACA,WAAA;ACCF;;ADEA;EACE,eAAA;EACA,UAAA;EAEA,cAAA;ACAF;;ADGA;EACE;IACE,gBAAA;IACA,YAAA;ECAF;AACF;ADGA;EACE,SAAA;EACA,UAAA;EACA,sBAAA;ACDF;;ADIA;EACE,qCAAA;EACA,yBAAA;EACA,cAAA;ACDF;;ADIA;EACE,kBAAA;ACDF;;ADIA;EACE,qBAAA;ACDF;;AAnCA;EACE,iBAAA;EACA,iBAAA;EACA,iBAAA;EACA,mBAAA;EACA,qBAAA;EACA,eAAA;AAsCF;AApCE;EACE,kBAAA;EACA,mBAAA;AAsCJ;ACjDI;EDeA;IACE,eAAA;EAqCJ;AACF;ACtDI;EDCJ;IAmBI,eAAA;IACA,gBAAA;EAsCF;EArCE;IACE,eAAA;EAuCJ;AACF","sourcesContent":[".wrapper {\n  margin: 0 auto;\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n}\n\n.container {\n  padding: 20px 0;\n  width: 85%;\n  // max-width: 980px;\n  margin: 0 auto;\n}\n\n@media (max-width: 360px) {\n  .wrapper {\n    max-width: 360px;\n    margin: auto;\n  }\n}\n\n* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\n\nbody {\n  font-family: \"Montserrat\", sans-serif;\n  background-color: #f5f5f4;\n  margin: 0 auto;\n}\n\ninput {\n  position: relative;\n}\n\na {\n  text-decoration: none;\n}","@import \"../../scssStyles/global\";\n@import \"../../scssStyles/variables\";\n@import \"../../scssStyles/mixins\";\n.box_container {\n  border: 1px solid;\n  line-height: 40px;\n  margin-top: 100px;\n  margin-bottom: 60px;\n  display: inline-block;\n  padding: 0 40px;\n\n  .box_content {\n    text-align: center;\n    align-items: center;\n  }\n\n  @include responsive(768px) {\n    .box_content {\n      font-size: 16px;\n    }\n  }\n  @include responsive(375px) {\n    padding: 0 10px;\n    margin-top: 50px;\n    .box_content {\n      font-size: 14px;\n    }\n  }\n}\n","//responsive\n@mixin responsive($breakpoint) {\n    @media screen and (max-width: $breakpoint) {\n        @content;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
