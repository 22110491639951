import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import Title from "../../components/title/Title";
import { useForm } from "react-hook-form";
import { bool, object, ref, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { SES } from "aws-sdk";
import "./contact.scss";

const ses = new SES({
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY,
});

const schema = object({
  companyName: string().required("会社名/学校名は必須になります。").trim(),
  department: string().trim(),
  fullName: string().required("お名前（フルネーム）は必須になります。").trim(),
  furigana: string().required("ふりがなは必須になります。").trim(),
  email: string()
    .required("メールアドレスは必須になります。")
    .email("正しい形式で入力してください。")
    .trim(),
  confirmEmail: string()
    .required("メールアドレス（確認）は必須になります。")
    .email("正しい形式で入力してください。")
    .oneOf([ref("email")], "確認用メールアドレスが異なります。")
    .trim(),

  content: string().required("お問い合わせ内容は必須になります。").trim(),
  privacyPolicy: bool().oneOf([true], "同意が必須になります。"),
});

const Contact = () => {
  const [confirm, setConfirm] = useState(false);
  const [success, setSuccess] = useState(false);
  const [step, setStep] = useState(1);

  const line_center = {
    border: "solid 1px #E4E3E3",
    width: "80px",
  };

  const {
    register,
    handleSubmit,
    reset,
    watch,
    trigger,
    formState: { errors, dirtyFields },
  } = useForm({
    defaultValues: {
      companyName: "",
      department: "",
      fullName: "",
      furigana: "",
      email: "",
      confirmEmail: "",
      phone: "",
      content: "",
      privacyPolicy: false,
    },
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const email = watch("email");

  const onSuccess = (values) => {
    setConfirm(true);
    onNextStep(2);
  };

  useEffect(() => {
    if (
      errors?.companyName ||
      errors?.fullName ||
      errors?.furigana ||
      errors?.email ||
      errors?.confirmEmail ||
      errors?.content ||
      errors?.privacyPolicy
    ) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [errors]);


  useEffect(() => {
    reset()
  },[reset])

  useEffect(() => {
    if (confirm) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [confirm]);

  const onError = (error) => {
    console.log(error);
  };

  const convertUTCtoJST = (utcDate) => {
    const jstOffset = 9 * 60; // Japan Standard Time (JST) is UTC+9
    const utcTime = utcDate.getTime();
    const jstTime = utcTime + jstOffset * 60 * 1000; // Convert to milliseconds
    return new Date(jstTime);
  };

  const sendEmail = async (values) => {
    const currentDate = convertUTCtoJST(new Date());
    const jstDate = currentDate.toLocaleString("ja-JP");

    const subject = "【BluseTech】お問い合わせ'";
    const emailBody = `
    ${jstDate} (JPT)

    お問い合わせがありました。
    ==================================================
      お問い合わせ情報
    ==================================================

    ■ 会社名/学校名：
    ${values.companyName}

    ■ 部署名/所属：
    ${values.department}

    ■ お名前（フルネーム）：
    ${values.fullName}

    ■ ふりがな：
    ${values.furigana}

    ■ メールアドレス：
    ${values.email}

    ■ 電話番号:
    ${values.phone}

    ■ お問い合わせ内容：
    ${values.content}

    ■ 個人情報の取り扱い：
    ${values.privacyPolicy ? "同意する" : "同意しない"}
  `;

    const params = {
      Destination: {
        ToAddresses: ["contact@blusetech.co.jp"],
      },
      Message: {
        Body: {
          Text: {
            Data: emailBody,
          },
        },
        Subject: {
          Data: subject,
        },
      },
      Source: "contact@blusetech.co.jp", // Replace with the verified email address from your AWS SES
    };

    try {
      await ses.sendEmail(params).promise();
      setSuccess(true);
      onNextStep(2);
    } catch (error) {
      throw ("Error sending email:", error);
    }
  };

  const onNextStep = () => {
    if (step === 1 && !confirm) {
      // If in Step 1 and not confirmed, show confirmation modal
      setConfirm(true);
      setStep((prevStep) => prevStep + 1);
    } else {
      setStep((prevStep) => prevStep + 1);
    }
  };

  const onPreviousStep = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setStep((prevStep) => prevStep - 1);
    setConfirm(false);
  };

  useEffect(() => {
    if (email && dirtyFields.confirmEmail) trigger("confirmEmail");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, dirtyFields.confirmEmail]);

  return (
    <>
      <Header />
      <main className="contact">
        <div className="contact__header">
          <Title addClass={"contact__title"}>CONTACT</Title>

          <div className="contact__steps">
            <div className={`contact__step ${step === 1 ? "active" : ""}`}>
              <div className="dot">
                <div />
              </div>
              <span className="title">入力</span>
            </div>
            <div className="line_center" style={line_center}></div>
            <div className={`contact__step ${step === 2 ? "active" : ""}`}>
              <div className="dot">
                <div />
              </div>
              <span className="title">確認</span>
            </div>
            <div className="line_center" style={line_center}></div>
            <div className={`contact__step ${step === 3 ? "active" : ""}`}>
              <div className="dot">
                <div />
              </div>
              <span className="title">完了</span>
            </div>
          </div>
        </div>

        {!success ? (
          <form
            className="form-container"
            onSubmit={handleSubmit(onSuccess, onError)}
          >
            <div className="contact__content">
              <p className="contact__content__txt">
                本ページは、弊社に関するお問い合わせ窓⼝となります。
                <br />
                お問い合わせ内容を確認後、担当よりご連絡いたします。
              </p>
              <p className="contact__content__txt" style={{ fontSize: 14 }}>
                <span className="star">※</span>は必須項⽬です。
              </p>
              {errors && (
                <p className="contact__content__txt--alert">
                  入力内容に誤りがあります。下記の項目をご確認ください。
                </p>
              )}
            </div>
            <div className="contact__form">
              {[
                {
                  name: "companyName",
                  txtShow: "会社名/学校名",
                  placeholder: "例）株式会社BluseTech",
                  required: true,
                },
                {
                  name: "department",
                  txtShow: "部署名/所属",
                  placeholder: "例）コンサルティング部",
                  required: false,
                },
                {
                  name: "fullName",
                  txtShow: "お名前（フルネーム）",
                  placeholder: "例）山田太郎",
                  required: true,
                },
                {
                  name: "furigana",
                  txtShow: "ふりがな",
                  placeholder: "例）やまだたろう",
                  required: true,
                },
                {
                  name: "email",
                  txtShow: "メールアドレス",
                  placeholder: "例）mail@blusetech.co.jp",
                  required: true,
                },
                {
                  name: "confirmEmail",
                  txtShow: "メールアドレス（確認）",
                  placeholder: "例）mail@blusetech.co.jp",
                  required: true,
                },
                {
                  name: "phone",
                  txtShow: "電話番号",
                  placeholder: "例）090-1234-5678",
                  required: false,
                },
              ].map((input, index) => (
                <div className="input-group" key={index}>
                  <label htmlFor={input.name} className="input-label">
                    {input.txtShow}{" "}
                    {input.required ? <span className="star">※</span> : null}
                  </label>
                  <div className="form-input">
                    <input
                      type="text"
                      id={input.name}
                      name={input.name}
                      placeholder={confirm ? '' : input.placeholder}
                      {...register(input.name)}
                      className={`${confirm ? "input--confirm" : null}`}
                      disabled={confirm}
                    />
                    {errors[input.name] && (
                      <p className="errorAlert">{errors[input.name].message}</p>
                    )}
                  </div>
                </div>
              ))}
              <div className="input-group" style={{ alignItems: "flex-start" }}>
                <label htmlFor="content" className="input-label">
                  お問い合わせ内容 <span className="star">※</span>
                </label>
                <div className="form-input">
                  <textarea
                    id="content"
                    name="content"
                    placeholder="採⽤⾯談をご希望の⽅は「採⽤⾯談希望」と記載の上、ご送信ください。担当者より追ってご連絡させていただきます。また、弊社への理解を深めるためにカジュアル⾯談の設定なども可能です（履歴書・職務経歴書などのドキュメント類は不要）"
                    {...register("content")}
                    className={`${confirm ? "input--confirm" : null}`}
                    disabled={confirm}
                  />
                  {errors.content && (
                    <p className="errorAlert">{errors.content.message}</p>
                  )}
                </div>
              </div>
              <div className="input-group">
                <label
                  htmlFor="privacyPolicy"
                  className="input-label"
                  style={{ alignItems: "flex-start" }}
                >
                  個人情報の取り扱い <span className="star">※</span>
                </label>
                <div className="form-input">
                  <div className="form-checkbox">
                    <input
                      type="checkbox"
                      id="privacyPolicy"
                      name="privacyPolicy"
                      {...register("privacyPolicy")}
                    />
                    同意する
                  </div>
                  <p className="txt--rules">
                    個⼈情報の取り扱いについて、詳しくは弊社の
                    <span
                      className="link"
                      onClick={() => (window.location.href = "/policy")}
                    >
                      プライバシーポリシー
                    </span>
                    をご覧ください。
                    <br />
                    {errors.privacyPolicy && (
                      <span className="errorAlert">
                        {errors.privacyPolicy.message}
                      </span>
                    )}
                  </p>
                </div>
              </div>
            </div>

            {confirm ? (
              <div className="contact__submit">
                <button
                  className="btn--back"
                  type="button"
                  onClick={() => {
                    setConfirm(false);
                    onPreviousStep();
                  }}
                >
                  戻る
                </button>
                <button
                  className="btn--next"
                  type="submit"
                  onClick={handleSubmit(sendEmail)}
                >
                  送 信
                </button>
              </div>
            ) : (
              <div className="contact__submit">
                <button
                  className="btn--next"
                  type="button"
                  onClick={handleSubmit(onSuccess, onError)}
                >
                  入力内容の確認
                </button>
              </div>
            )}
          </form>
        ) : (
          <div className="contact__notification">
            <div className="contact__notification__title">
              お問い合わせありがとうございます。
            </div>
            <div className="contact__notification__content">
              内容を確認させていただき、担当よりご連絡させていただきます。
              <br />
              数⽇経っても折り返しのご連絡がない場合、送信エラーなどの可能性がございます。
              <br />
              お⼿数ですが、改めてお問い合わせいただけますと幸いです。
            </div>
          </div>
        )}
      </main>
      <Footer />
    </>
  );
};

export default Contact;
