import React from "react";

import ReactDOM from "react-dom/client";

import App from "./App";

import reportWebVitals from "./reportWebVitals";

 

const root = ReactDOM.createRoot(document.getElementById("root"));

 

if ("serviceWorker" in navigator) {

  window.addEventListener("load", () => {

    navigator.serviceWorker.register("/sw.js").then(

      (registration) => {

        console.log(

          "Service Worker registered with scope:",

          registration.scope

        );

      },

      (err) => {

        console.error("Service Worker registration failed:", err);

      }

    );

  });

}

 

root.render(<App />);

 

// If you want to start measuring performance in your app, pass a function

// to log results (for example: reportWebVitals(console.log))

// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals();
